import "./styles.scss";

export const CartView = () => {
  return (
    <div>
      <h1>Hello im Cart View page</h1>
      <button>push me</button>
    </div>
  );
};
